// extracted by mini-css-extract-plugin
export var aiAwardsSection = "W_j9";
export var aiBenefitsSection = "W_j2";
export var aiBusinessSection = "W_j5";
export var aiCasesSection = "W_j6";
export var aiCompanySection = "W_j7";
export var aiIndustriesSection = "W_j1";
export var aiPracticesSection = "W_j3";
export var aiPrimeSection = "W_jZ";
export var aiProcessSection = "W_j4";
export var aiProjLogoSection = "W_j8";
export var aiQuoteSection = "W_kb";
export var aiServicesIconSection = "W_j0";